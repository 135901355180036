import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from "react-i18next";
import axios from 'axios'
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { alpha } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { DataGrid } from '@material-ui/data-grid';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MaterialTable from '@material-table/core';
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";
import tableLocalizations from '../common/tableLocalizations.json';
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
    },
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: 64,
        height: 64
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flex: 1,
        '& span': {
            // color: theme.palette.common.white
        }
    },
    image: {
        width: '100%'
    },
    formControl: {
        flex: 1,
        display: 'flex'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: "space-around",
        marginTop: 16,
        marginBottom: 8
    },
    formControlLabel: {
        borderRadius: 9,
        border: '1px solid #DDD',
        padding: 8,
        minWidth: 110,
        margin: 4
    },
    mainBtn: {
        minHeight: 144,
        flexWrap: 'wrap',
        display: "block"
    },
    mainBtnIcon: {
        display: 'block',
        width: '100%',
        fontSize: '6rem',
        color: alpha(theme.palette.primary.main, 0.8),
        '&:hover': {
            color: alpha(theme.palette.primary.main, 1)
        }
    }
}));

export default function GetRoomTransactionsDialog(props) {

    const { t, i18n } = useTranslation()
    const classes = useStyles();
    const fixedHeightSkeleton = clsx(classes.paper, classes.fixedHeightSkeleton);

    const [transactions, setTransactions] = useState([]);

    const currencyFormatter = new Intl.NumberFormat('uk-UA', {
        style: 'currency',
        currency: 'UAH',
    });
    const datetimeFormatter = new Intl.DateTimeFormat('uk-UA', {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false
    });

    const columns = [
        { field: 'id', headerName: t('id'), width: 75, headerAlign: 'center', align: 'center' },

        {
            field: 'at',
            headerName: t('at'),
            width: 200,
            type: 'dateTime',
            valueFormatter: ({ value }) => datetimeFormatter.format(new Date(value)),
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'amount',
            headerName: t('amount'),

            valueFormatter: ({ value }) => currencyFormatter.format(Number(value / 100)),
            headerAlign: 'center',
            align: 'right'
        },
        { field: 'type', headerName: t('type'), width: 75, headerAlign: 'center', align: 'center' },

    ];

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
        axios
            .get(`/api/v1/nodes/${props.parentNode.id}/transactions`)
            .then(response => {
                console.log(response)
                for (let i = 0; i < response.data.transactions.length; i++) {
                    response.data.transactions[i].amount = response.data.transactions[i].amount * 0.01
                    response.data.transactions[i].type = t(response.data.transactions[i].type)
                }
                setTransactions(response.data.transactions)
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
    };
    const handleClose = () => setOpen(false);

    return (
        <React.Fragment>
            <div style={{ flexGrow: 1, padding: '0 4px' }}>
                <Button
                    variant="outlined"
                    color="primary"
                    aria-label="add"
                    onClick={handleClickOpen}
                    size="large"
                    style={{ width: '100%' }}
                    className={classes.mainBtn}
                >
                    <Typography variant="h6" component="h6">{t('room_transactions')}</Typography>
                    <Icon fontSize="large" classes={{ root: classes.mainBtnIcon }}>receipt_long</Icon>
                </Button>
            </div >
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={"lg"}>
                <div className={classes.paper}>

                    <DialogTitle id="form-dialog-title" style={{ width: '100%' }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <span />
                            <span>{t('room_transactions')} "{props.parentNode.name}"</span>
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>

                    <DialogContent style={{ width: '100%' }}>
                        <div style={{ height: 400, width: '100%' }}>

                            {transactions.length && <Grid container spacing={0}>

                                <Grid item xs={12} md={12} lg={12}>
                                    {!transactions.length
                                        ?
                                        <Paper className={fixedHeightSkeleton}>
                                            <Skeleton animation="wave" width="100%" />
                                            <Skeleton variant="circle" width={40} height={40} />
                                            <Skeleton animation="wave" width="100%" />
                                        </Paper>
                                        :
                                        <MaterialTable
                                            style={{ boxShadow: "none" }}
                                            columns={[



                                                { field: 'machine_transaction_id', title: t('id'), width: 60, defaultSort: 'desc' },
                                                { field: 'at', title: t('at'), dateSetting: { locale: 'uk' }, type: 'datetime' },

                                                {
                                                    field: 'amount', title: t('amount'), currencySetting: {
                                                        locale: 'uk',
                                                        currencyCode: props.currency,
                                                    }, type: 'currency', cellStyle: { textAlign: 'left' }
                                                },
                                                { field: 'type', title: t('type') },

                                                /*   {title: t('id'), field: "ID", cellStyle: {textAlign: 'center'}},
                                                  {title: t('name'), field: "name", cellStyle: {textAlign: 'center'}},
                                                  {title: t('address'), field: "address", cellStyle: {textAlign: 'center'}},
                                                  {title: t('description'), field: "description", cellStyle: {textAlign: 'center'} }, */

                                            ]}

                                            localization={tableLocalizations[i18n.language]}
                                            data={transactions}

                                            options={{
                                                // actionsColumnIndex: -1,

                                                header: true,
                                                search: false,
                                                showTitle: false,
                                                pageSize: 5,
                                                headerStyle: { position: 'sticky', top: 0, textAlign: 'center' },
                                                rowStyle: { textAlign: 'center' },

                                                paginationType: 'stepped',



                                            }}




                                        />
                                    }
                                </Grid>
                            </Grid>}


                        </div>
                    </DialogContent>
                    {/*<pre>{JSON.stringify(transactions, null, 4)}</pre>*/}
                </div>
            </Dialog>


        </React.Fragment >
    );
}

import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, Hidden, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import SvgIcon from "@material-ui/core/SvgIcon";
import { logout } from "../../../plugins/AuthService";
import NavigationDrawer from "./NavigationDrawer";
import theme from "../../../theme";

const useStyles = makeStyles(theme => ({
    appBar: {
        boxShadow: theme.shadows[1],
        backgroundColor: theme.palette.primary.main,
        zIndex: 9999
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between"
    },
    menuButtonText: {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.h6.fontWeight,
        color: theme.palette.common.white
    },
    brandText: {
        // fontFamily: "'Baloo Bhaijaan', cursive",
        fontWeight: 400
    },
    noDecoration: {
        textDecoration: "none !important"
    },
    image: {
        maxHeight: 36,
        margin: '0px 8px -8px 0'
    }
}));

export default function DashBoardNavbar(props) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        localStorage.setItem('lang', lng);
        i18n.changeLanguage(lng);
    };

    const [selectedTab] = useState(null);

    const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

    const handleMobileDrawerOpen = useCallback(() => {
        setIsMobileDrawerOpen(true);
    }, [setIsMobileDrawerOpen]);

    const handleMobileDrawerClose = useCallback(() => {
        setIsMobileDrawerOpen(false);
    }, [setIsMobileDrawerOpen]);

    const logOut = () => {
        logout();
    }

    const menuItems = [
        {
            name: 'mainmenu.logout',
            onClick: logOut,
            icon: <LockOpenIcon className="text-white" />
        }
    ];

    // t('mainmenu.logout')
    // t('mainmenu.login')

    return (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <div>
                        <Typography
                            variant="h4"
                            className={classes.brandText}
                            display="inline"
                            style={{ color: theme.palette.primary.light }}
                        >
                            SKILL
                        </Typography>
                        <Typography
                            variant="h4"
                            className={classes.brandText}
                            display="inline"
                            style={{ color: theme.palette.primary.contrastText }}
                        >
                            GAMES
                        </Typography>
                    </div>
                    <div>
                        <Hidden mdUp>
                            <IconButton
                                className={classes.menuButton}
                                onClick={handleMobileDrawerOpen}
                                aria-label="Open Navigation"
                            >
                                <MenuIcon color="primary" />
                            </IconButton>
                        </Hidden>
                        <Hidden smDown>
                            {menuItems.map(element => {
                                if (element.link) {
                                    return (
                                        <Link
                                            key={element.name}
                                            to={element.link}
                                            className={classes.noDecoration}
                                            onClick={handleMobileDrawerClose}
                                        >
                                            <Button
                                                color="secondary"
                                                size="large"
                                                classes={{ text: classes.menuButtonText }}
                                            >
                                                {t(element.name)}
                                            </Button>
                                        </Link>
                                    );
                                }
                                return (
                                    <Button
                                        color="secondary"
                                        size="large"
                                        onClick={element.onClick}
                                        classes={{ text: classes.menuButtonText }}
                                        key={element.name}
                                    >
                                        {t(element.name)}
                                    </Button>
                                );
                            })}


                            {i18n.language === 'en'
                                ? <IconButton aria-label="ukrainian" onClick={() => changeLanguage('uk')}>
                                    <SvgIcon style={{ marginTop: 4, marginBottom: -4 }}>
                                        <rect width="30" height="12" y="2" fill="#005bbb" />
                                        <rect width="30" height="6" fill="#ffd500" y="8" />
                                    </SvgIcon>
                                </IconButton>
                                : <IconButton aria-label="english" onClick={() => changeLanguage('en')}>
                                    <SvgIcon viewBox="0 0 30 24" style={{ marginTop: 4, marginBottom: -4 }}>
                                        <clipPath id="t">
                                            <path d="M15,8 h15 v8 z v8 h-15 z h-15 v-8 z v-8 h15 z" />
                                        </clipPath>
                                        <path d="M0,0 v15 h30 v-15 z" fill="#00247d" />
                                        <path d="M0,0 L30,15 M30,0 L0,15" stroke="#fff" strokeWidth="3" />
                                        <path d="M0,0 L30,15 M30,0 L0,15" clipPath="url(#t)" stroke="#cf142b" strokeWidth="2" />
                                        <path d="M15,0 v15 M0,8 h30" stroke="#fff" strokeWidth="5" />
                                        <path d="M15,0 v15 M0,8 h30" stroke="#cf142b" strokeWidth="3" />
                                    </SvgIcon>
                                </IconButton>
                            }


                        </Hidden>
                    </div>
                </Toolbar>
            </AppBar>

            <NavigationDrawer
                menuItems={menuItems}
                anchor="right"
                open={isMobileDrawerOpen}
                selectedItem={selectedTab}
                onClose={handleMobileDrawerClose}
            />
        </>
    );
}


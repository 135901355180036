import React from 'react';
import {HashRouter as Router, Switch} from 'react-router-dom';
import MainLayoutRoutes from "./layouts/Main/route";
import EmptyLayoutRoutes from "./layouts/Empty/route";
import DashboardLayoutRoutes from "./layouts/Dashboard/route";
import Home from "./components/Home";
import SignIn from "./components/common/Login/SingIn";
import Page404 from "./components/404";
import Page401 from "./components/401";
import DashboardIndex from "./components/Dashboard";
import NodeIndex from "./components/Node";

// voyager3m@gmail.com

const warn = console.warn;
export function logWarning(...warnings) {
    let showWarning = true;
    warnings.forEach(warning => {
        if (warning.includes("UNSAFE_")) showWarning = false;
        else if (warning.includes("SourceMap")) showWarning = false;
        else if (warning.includes("DevTools")) showWarning = false;
        else if (warning.includes("WebSocket")) showWarning = false;
        else if (warning.includes("import SvgIcon")) showWarning = false;
    });
    if (showWarning) warn(...warnings);
}
console.warn = logWarning;


export default function App() {
    return (
        <Router>
            <Switch>
                <MainLayoutRoutes exact path="/" component={Home}/>
                 <MainLayoutRoutes exact path="/login" component={SignIn}/> 
                <DashboardLayoutRoutes exact path="/dashboard" component={DashboardIndex}/>
                <DashboardLayoutRoutes path="/node/:id" component={NodeIndex}/>
                <EmptyLayoutRoutes path="/denied" component={Page401}/>
                <EmptyLayoutRoutes path="/*" component={Page404}/>
            </Switch>
        </Router>
    );
};

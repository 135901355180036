import React from 'react';
import DocumentTitle from 'react-document-title';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => (
    {
        root: {
            flexGrow: 1,
        },
    }
));

export default function Home(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <DocumentTitle title={'Andromeda || ' + t('mainmenu.home')}>
            <React.Fragment>
                <Box className={classes.root}>
                    LOGIN ?
                </Box>
            </React.Fragment>
        </DocumentTitle>
    );
};



import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from "react-i18next";
import axios from 'axios'
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { alpha } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
//import { DataGrid } from '@material-ui/data-grid';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MaterialTable from '@material-table/core';
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";
import tableLocalizations from '../common/tableLocalizations.json';
import Grid from "@material-ui/core/Grid";
import CounterDateTimePicker from "./DateTimePicker";
import moment from "moment";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

//import SummarizeIcon from '@mui/icons-material/Summarize';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
    },
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: 64,
        height: 64
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flex: 1,
        '& span': {
            // color: theme.palette.common.white
        }
    },
    image: {
        width: '100%'
    },
    formControl: {
        flex: 1,
        display: 'flex'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: "space-around",
        marginTop: 16,
        marginBottom: 8
    },
    formControlLabel: {
        borderRadius: 9,
        border: '1px solid #DDD',
        padding: 8,
        minWidth: 110,
        margin: 4
    },
    mainBtn: {
        minHeight: 144,
        flexWrap: 'wrap',
        display: "block"
    },
    mainBtnIcon: {
        display: 'block',
        width: '100%',
        fontSize: '6rem',
        color: alpha(theme.palette.primary.main, 0.8),
        '&:hover': {
            color: alpha(theme.palette.primary.main, 1)
        }
    }
}));

export default function GetReportDialog(props) {

    const { t, i18n } = useTranslation()
    const classes = useStyles();
    const fixedHeightSkeleton = clsx(classes.paper, classes.fixedHeightSkeleton);

    const [report, setReport] = useState({});



    const currencyFormatter = new Intl.NumberFormat('uk-UA', {
        style: 'currency',
        currency: 'UAH',
    });
    const datetimeFormatter = new Intl.DateTimeFormat('uk-UA', {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false
    });

    const columns = [
        { field: 'id', headerName: t('id'), width: 75, headerAlign: 'center', align: 'center' },

        {
            field: 'at',
            headerName: t('at'),
            width: 200,
            type: 'dateTime',
            valueFormatter: ({ value }) => datetimeFormatter.format(new Date(value)),
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'amount',
            headerName: t('amount'),

            valueFormatter: ({ value }) => currencyFormatter.format(Number(value / 100)),
            headerAlign: 'center',
            align: 'right'
        },
        { field: 'type', headerName: t('type'), width: 75, headerAlign: 'center', align: 'center' },

    ];

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
        /*       axios
                  //https://staging.back.fish-games.com.ua/api/v1/nodes/$1/report?from=2022-01-12T07:20:50.52Z&to=2022-10-12T07:20:50.52Z
                  .get(`/api/v1/nodes/${props.parentNode.id}/report?from=2022-01-12T07:20:50.52Z&to=2022-10-12T07:20:50.52Z`)
                  .then(response => {
                      console.log("fff", response.data.report)
                     response.data.report.total_in = response.data.report.total_in * 0.01  
                          console.log("fff2", response.data.report.total_in)  
                          response.data.report.total_out = response.data.report.total_out * 0.01  
                           response.data.report.saldo = response.data.report.saldo * 0.01  
                      setReport(response.data.report)
                  })
                  .catch(error => {
                      console.log(error.response)
                      NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
                  })*/
    };
    const [selectedDateStart, setSelectedDateStart] = useState(new Date());
    const [selectedDateEnd, setSelectedDateEnd] = useState(new Date());
    const handleDateChangeStart = (date) => {
        console.log('handleDateChangeStart date: ', date)
        setSelectedDateStart(date)
    };
    const handleDateChangeEnd = (date) => {
        console.log('handleDateChangeStart date: ', date)
        setSelectedDateEnd(date)
    };
    const handleCloseButton = () => {
        console.log("startdate: ", selectedDateStart)
        console.log("enddate: ", selectedDateEnd)
        //   eslint-disable-next-line
        axios.get(`/api/v1/nodes/${props.parentNode.id}/report` + `?from=` + moment(selectedDateStart).toISOString() + `&to=` + moment(selectedDateEnd).toISOString()
        )
            .then(response => {
                if (response.data.report.machines) {
                    for (let i = 0; i < response.data.report.machines.length; i++) {
                        response.data.report.machines[i].total_in = (response.data.report.machines[i].total_in * 0.01)
                        response.data.report.machines[i].saldo = (response.data.report.machines[i].saldo * 0.01)
                        response.data.report.machines[i].total_out = (response.data.report.machines[i].total_out * 0.01)
                    }

                }
                response.data.report.total_in = (response.data.report.total_in * 0.01).toFixed(2) + " " + 'UAH'
                response.data.report.total_out = (response.data.report.total_out * 0.01).toFixed(2) + " " + 'UAH'
                response.data.report.saldo = (response.data.report.saldo * 0.01).toFixed(2) + " " + 'UAH'
                setReport(response.data)
                console.log("fff25", response.data)
            }).catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000)
            })
    };
    const handleClose = () => {
        setReport({});
        setOpen(false);
    }

    return (
        <React.Fragment>


            <div style={{ flexGrow: 1, padding: '0 4px' }}>
                <Button
                    variant="outlined"
                    color="primary"
                    aria-label="add"
                    onClick={handleClickOpen}
                    size="large"
                    style={{ width: '100%' }}
                    className={classes.mainBtn}
                >
                    <Typography variant="h6" component="h6">{t('report')}</Typography>
                    <Icon fontSize="large" classes={{ root: classes.mainBtnIcon }}>equalizer</Icon>
                </Button>
            </div >
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={"lg"}>
                <div className={classes.paper}>

                    <DialogTitle id="form-dialog-title" style={{ width: '100%' }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <span />
                            <span>{t('report')} "{props.parentNode.name}"</span>
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>

                    <DialogContent style={{ width: '100%' }}>
                        <div style={{ height: 400, width: '100%' }}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} md={5} lg={5}>
                                    <Typography variant={"h6"} style={{ margin: '12px auto' }}>
                                        {t('at_start')}: &nbsp;
                                        <CounterDateTimePicker setNewDateTime={handleDateChangeStart} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={5} lg={5}>
                                    <Typography variant={"h6"} style={{ margin: '12px auto' }}>
                                        {t('at_end')}: &nbsp;
                                        <CounterDateTimePicker setNewDateTime={handleDateChangeEnd} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={1} lg={1}>
                                    <Button onClick={handleCloseButton} variant={"contained"} type={'reset'} fullWidth color={'primary'} size={'large'}>
                                        {t('ok')}
                                    </Button>
                                </Grid>
                            </Grid>

                            {report && <Grid container spacing={0}>

                                <Grid item xs={12} md={12} lg={12}>
                                    {!report
                                        ?
                                        <Paper className={fixedHeightSkeleton}>
                                            <Skeleton animation="wave" width="100%" />
                                            <Skeleton variant="circle" width={40} height={40} />
                                            <Skeleton animation="wave" width="100%" />
                                        </Paper>
                                        :



                                        props.parentNode.type === 'machine'
                                            ?
                                            report.status && <Grid container spacing={0}>
                                                <Grid item xs={12} md={4} lg={4}>
                                                    <Box sx={{ m: 1, width: '35ch' }}>
                                                        <TextField label={t('total_in')}
                                                            defaultValue=' '
                                                            value={report.report.total_in}
                                                            InputProps={{
                                                                readOnly: true,
                                                                // endAdornment: (
                                                                //     <InputAdornment position="end">
                                                                //         <MonetizationOnIcon sx={{ color: 'action.active', mr: 1, my: 2.2 }} />
                                                                //     </InputAdornment>
                                                                // ),
                                                            }} />

                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={4} lg={4}>
                                                    <Box sx={{ m: 1, width: '35ch' }}>

                                                        <TextField label={t('total_out')}
                                                            defaultValue=' '
                                                            value={report.report.total_out}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }} />


                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={4} lg={4}>
                                                    <Box sx={{ m: 1, width: '35ch' }}>

                                                        <TextField label={t('saldo')}
                                                            defaultValue=' '
                                                            value={report.report.saldo}
                                                            InputProps={{
                                                                readOnly: true,
                                                                // endAdornment: (
                                                                //     <InputAdornment position="end">
                                                                //         <MonetizationOnIcon sx={{ color: 'action.active', mr: 1, my: 2.2 }} />
                                                                //     </InputAdornment>
                                                                // ),
                                                            }} />

                                                    </Box>
                                                </Grid>
                                            </Grid>

                                            : report.status && <div>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12} md={4} lg={4}>
                                                        <Box sx={{ m: 1, width: '35ch' }}>
                                                            <TextField label={t('total_in')}
                                                                defaultValue=' '
                                                                value={report.report.total_in}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    // endAdornment: (
                                                                    //     <InputAdornment position="end">
                                                                    //         <MonetizationOnIcon sx={{ color: 'action.active', mr: 1, my: 2.2 }} />
                                                                    //     </InputAdornment>
                                                                    // ),
                                                                }} />

                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={4} lg={4}>
                                                        <Box sx={{ m: 1, width: '35ch' }}>

                                                            <TextField label={t('total_out')}
                                                                defaultValue=' '
                                                                value={report.report.total_out}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }} />


                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={4} lg={4}>
                                                        <Box sx={{ m: 1, width: '35ch' }}>

                                                            <TextField label={t('saldo')}
                                                                defaultValue=' '
                                                                value={report.report.saldo}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    // endAdornment: (
                                                                    //     <InputAdornment position="end">
                                                                    //         <MonetizationOnIcon sx={{ color: 'action.active', mr: 1, my: 2.2 }} />
                                                                    //     </InputAdornment>
                                                                    // ),
                                                                }} />

                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                {report.report.machines && <MaterialTable
                                                    style={{ boxShadow: "none" }}
                                                    columns={[

                                                        { field: 'id', title: t('id'), hidden: true },
                                                        { field: 'room_id', title: t('id'), defaultSort: 'desc', hidden: true },
                                                        { field: 'name', title: t('name') },
                                                        { field: 'serial_number', title: t('serial_number'), hidden: false },
                                                        { field: 'last_sync', title: t('last_sync'), dateSetting: { locale: 'uk' }, type: 'datetime' },
                                                        { field: 'room_name', title: t('room_name') },

                                                        {
                                                            field: 'total_in', title: t('total_in'), currencySetting: {
                                                                locale: 'uk',
                                                                currencyCode: 'UAH',
                                                            }, type: 'currency', cellStyle: { textAlign: 'left' }
                                                        },
                                                        {
                                                            field: 'total_out', title: t('total_out'), currencySetting: {
                                                                locale: 'uk',
                                                                currencyCode: 'UAH',
                                                            }, type: 'currency', cellStyle: { textAlign: 'left' }
                                                        },
                                                        {
                                                            field: 'saldo', title: t('saldo'), currencySetting: {
                                                                locale: 'uk',
                                                                currencyCode: 'UAH',
                                                            }, type: 'currency', cellStyle: { textAlign: 'left' }, headerStyle: { align: 'right' },
                                                        }

                                                    ]}

                                                    localization={tableLocalizations[i18n.language]}
                                                    data={report.report.machines}

                                                    options={{
                                                        // actionsColumnIndex: -1,

                                                        header: true,
                                                        search: false,
                                                        showTitle: false,
                                                        pageSize: 5,
                                                        headerStyle: { position: 'sticky', top: 0, textAlign: 'center' },
                                                        rowStyle: { textAlign: 'center' },

                                                        paginationType: 'stepped',



                                                    }}




                                                />
                                                }
                                            </div>
                                    }
                                </Grid>
                            </Grid>}


                        </div>
                    </DialogContent>
                    {/*<pre>{JSON.stringify(transactions, null, 4)}</pre>*/}
                </div>
            </Dialog >


        </React.Fragment >
    );
}

import React from 'react';
import {alpha, makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    progressColorPrimary: {
        color: alpha(theme.palette.primary.main, 0.2)// + '!important'
    },
    skeletonRoot:{
        minWidth: 200,
        width: '90%',
        backgroundColor: alpha(theme.palette.primary.main, 0.2),

    },
    skeletonWrap:{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(10, 0)

    },
    circleWrap:{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 200,
    }
}));

const Preloader = ({size, thickness, type}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {
                type === 'linear'
                    ? <div className={classes.skeletonWrap}>
                        <Skeleton variant="text" height={20} classes={{root: classes.skeletonRoot}}/>
                        <Skeleton variant="text" height={10} classes={{root: classes.skeletonRoot}}/>
                        <Skeleton variant="text" height={10} classes={{root: classes.skeletonRoot}}/>
                        <Skeleton variant="text" height={10} classes={{root: classes.skeletonRoot}}/>
                        <Skeleton variant="text" height={10} classes={{root: classes.skeletonRoot}}/>
                    </div>
                    : <div className={classes.circleWrap}>
                        <CircularProgress size={size || 72} thickness={thickness || 4} color="primary" classes={{colorPrimary: classes.progressColorPrimary}}/>
                    </div>
            }
        </div>
    );
}

export default Preloader

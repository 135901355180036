import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from "react-i18next";
import axios from 'axios'
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

//import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import BuildIcon from '@material-ui/icons/Build';
import FaceIcon from '@material-ui/icons/Face';
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { alpha } from "@material-ui/core";
//import Icon from "@material-ui/core/Icon";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: 64,
        height: 64
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flex: 1,
        '& span': {
            // color: theme.palette.common.white
        }
    },
    image: {
        width: '100%'
    },
    formControl: {
        flex: 1,
        display: 'flex'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: "space-around",
        marginTop: 16,
        marginBottom: 8
    },
    formControlLabel: {
        borderRadius: 9,
        border: '1px solid #DDD',
        padding: 8,
        minWidth: 110,
        margin: 4
    },
    mainBtn: {
        minHeight: 144,
        flexWrap: 'wrap',
        display: "block"
    },
    mainBtnIcon: {
        display: 'block',
        width: '100%',
        fontSize: '6rem',
        color: alpha(theme.palette.primary.main, 0.8),
        '&:hover': {
            color: alpha(theme.palette.primary.main, 1)
        }
    }
}));

const userTypes = [

    {
        type: "Admin",
        accepted: ["room"]
    },
    {
        type: "Service",
        accepted: ["room"]
    },
    {
        type: "Operator",
        accepted: ["room"]
    }
]

/* DO NOT REMOVE NEXT LINES! It's NOT a comment. It's needed to auto generate missing translation fields*/
// t('user_types.owner')
// t('user_types.manager')
// t('user_types.tech')
// t('user_types.operator')

const userIcon = type => {
    switch (type) {

        case 'Admin':
            return <HowToRegIcon />
        case 'Service':
            return <BuildIcon />
        case 'Operator':
            return <FaceIcon />
        default:
            return null

    }
}

export default function EditCardDialog(props) {

    const { t } = useTranslation()
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        //  setOpen(false);
        props.closeDialog()
    };


    const [newData, setNewData] = useState({
        role: '',
        description: ''// nodeTypes.filter(nodeType=>nodeType.accepted.includes(props.parentNode.type))[0].type
    });

    const onChange = ({ target: { name, value } }) => {
        setNewData({ ...newData, [name]: value })
    };

    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }


        let errors = 0;

        Object.keys(newData).forEach(e => {
            if (!newData[e]) {
                NotificationManager.error(`${t(e)}: ${t('field_is_required')}`, 'Oops!', 6666)
                errors++
            }
        });

        if (errors) return


        axios.put(`/api/v1/nodes/${props.cardsData.room_node}/card/${newData.id}`, newData)
            .then(response => {

                if (response.data.status) {
                    NotificationManager.success(t('card_edit_success ') + newData.uid_card, 'Success!', 5000);

                }
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
        props.closeDialog()
    };

    useEffect(() => {
        setNewData(props.cardsData)
        setOpen(props.isOpen)
        // eslint-disable-next-line
    }, [props.cardsData.id])

    return (

        <div style={{ flexGrow: 1, padding: '0 4px' }}>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" classes={{ paper: classes.paper }}>
                <div className={classes.paper}>
                    <form className={classes.form} noValidate onSubmit={onSubmit}>
                        <DialogTitle id="form-dialog-title-1">{t('edit_card')} : {newData.uid_card}</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="caption" component="div">
                                <div dangerouslySetInnerHTML={{ __html: t('add_user_dialog_description') }} />
                            </DialogContentText>

                            <FormControl component="fieldset" className={classes.formControl}>
                                <Typography variant="h5" component="legend">{t('user_types.text')}</Typography>

                            </FormControl>



                            <RadioGroup row aria-label="position"
                                className={classes.radioGroup}
                                name="role"
                                defaultValue={newData.role}
                                value={newData.role}
                                onChange={onChange}
                            >
                                {
                                    userTypes.map((userType) => {
                                        return <FormControlLabel
                                            value={userType.type}
                                            control={<Radio color="primary" />}
                                            label={
                                                <>
                                                    {userIcon(userType.type)}
                                                    <small style={{ top: -12, position: 'relative' }}>
                                                        {t(`user_types.${userType.type}`)}
                                                    </small>
                                                </>
                                            }
                                            labelPlacement="top"
                                            key={userType.type}
                                            className={classes.formControlLabel}
                                        />
                                    })
                                }
                            </RadioGroup>
                            <Divider />
                            <TextField
                                margin="dense"
                                name="description"
                                label={t('description')}
                                type="description"
                                fullWidth
                                value={newData.description}
                                onInput={onChange}

                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary" variant="outlined"
                                className={classes.submit}>
                                {t('cancel')}
                            </Button>
                            <Button type="submit" variant="contained" color="primary"
                                className={classes.submit}>
                                {t('edit')}
                            </Button>
                        </DialogActions>
                    </form>
                    {/*<pre>{JSON.stringify(newData, null, 4)}</pre>*/}
                </div>
            </Dialog>
        </div>

    );
}

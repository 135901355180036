import React from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from "react-i18next";
import axios from 'axios'
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import makeStyles from "@material-ui/core/styles/makeStyles";

import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";
import Icon from "@material-ui/core/Icon";
//import {alpha} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // width: 800,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: 64,
        height: 64
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flex: 1,
        '& span': {
            // color: theme.palette.common.white
        }
    },
    image: {
        width: '100%'
    },
    formControl: {
        flex: 1,
        display: 'flex'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: "space-around",
        marginTop: 16,
        marginBottom: 8
    },
    formControlLabel: {
        borderRadius: 9,
        border: '1px solid #DDD',
        padding: 8,
        width: 110,
        margin: 4
    },
    mainBtn: {
        minHeight: 144,
        flexWrap: 'wrap',
        display: "block"
    },
    mainBtnIcon: {
        display: 'block',
        width: '100%',
        fontSize: '6rem',
        color: 'red'
    }
}));


/* DO NOT REMOVE NEXT LINES! It's NOT a comment. It's needed to auto generate missing translation fields*/
// t('node_types.node')
// t('node_types.room')
// t('node_types.group')
// t('node_types.point')




export default function EditNodeDialog(props) {

    const { t } = useTranslation();
    const history = useHistory()
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };





    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        console.log('submit')
        let errors = 0;


        if (errors) return

        axios.delete(`/api/v1/nodes/${props.parentNode.id}`)
            .then(response => {
                console.log('/api/v1/nodes  response: ', response)
                if (response.status) {

                    NotificationManager.success(t('node_delete_success'), 'Success!', 3000);
                    props.updateTree();
                    handleClose();
                    history.go(-1);
                }
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
                handleClose();
            })
     
    };


    return (
        <div style={{ flexGrow: 1, padding: '0 4px' }}>
            <Button
                variant="outlined"
                color="primary"
                aria-label="add"
                onClick={handleClickOpen}
                size="large"
                style={{ width: '100%' }}
                className={classes.mainBtn}
            >
                <Typography variant="h6" component="h6">{t('delete')}</Typography>
                <Icon fontSize="large" classes={{ root: classes.mainBtnIcon }}>device_hub</Icon>
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" classes={{ paper: classes.paper }}>
                <div className={classes.paper}>
                    <form className={classes.form} noValidate onSubmit={onSubmit}>
                        <DialogTitle id="form-dialog-title">{t('delete_node')} "{props.parentNode.name}"</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="caption" component="div">
                                <div dangerouslySetInnerHTML={{ __html: t('delete_node_dialog_description') }} />
                            </DialogContentText>




                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary" variant="outlined"
                                className={classes.submit}>
                                {t('cancel')}
                            </Button>
                            <Button type="submit" variant="contained" color="primary"
                                className={classes.submit}>
                                {t('delete')}
                            </Button>
                        </DialogActions>
                    </form>
                    {/*<pre>{JSON.stringify(newData, null, 4)}</pre>*/}
                </div>
            </Dialog>
        </div>
    );
}

//user-41129202
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from "react-i18next";
import axios from 'axios'
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import BuildIcon from '@material-ui/icons/Build';
import FaceIcon from '@material-ui/icons/Face';
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
//import {alpha} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: 64,
        height: 64
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flex: 1,
        '& span': {
            // color: theme.palette.common.white
        }
    },
    image: {
        width: '100%'
    },
    formControl: {
        flex: 1,
        display: 'flex'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: "space-around",
        marginTop: 16,
        marginBottom: 8
    },
    formControlLabel: {
        borderRadius: 9,
        border: '1px solid #DDD',
        padding: 8,
        minWidth: 110,
        margin: 4
    },
    mainBtn: {
        minHeight: 144,
        flexWrap: 'wrap',
        display: "block"
    },
    mainBtnIcon: {
        display: 'block',
        width: '100%',
        fontSize: '6rem',
        color: 'green'
    }
}));

const userTypes = [
    {
        type: "owner",
        accepted: ["node"]
    },
    {
        type: "admin",
        accepted: ["node"]
    },
    {
        type: "service",
        accepted: ["room"]
    },
    {
        type: "client",
        accepted: ["room"]
    }
]

/* DO NOT REMOVE NEXT LINES! It's NOT a comment. It's needed to auto generate missing translation fields*/
// t('user_types.owner')
// t('user_types.manager')
// t('user_types.tech')
// t('user_types.operator')

const userIcon = type => {
    switch (type) {
        case 'owner':
            return <AssignmentIndIcon />
        case 'admin':
            return <HowToRegIcon />
        case 'service':
            return <BuildIcon />
        case 'client':
            return <FaceIcon />
        default:
            return null

    }
}

export default function AddUserDialog(props) {

    const { t } = useTranslation()
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const [newData, setNewData] = useState({
        login: '',
        node: props.parentNode.id,
        permission: ''// nodeTypes.filter(nodeType=>nodeType.accepted.includes(props.parentNode.type))[0].type
    });

    const onChange = ({ target: { name, value } }) => {
        setNewData({ ...newData, [name]: value })
    };

    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        console.log('submit')
        let errors = 0;

        Object.keys(newData).forEach(e => {
            if (!newData[e]) {
                NotificationManager.error(`${t(e)}: ${t('field_is_required')}`, 'Oops!', 6666)
                errors++
            }
        });

        if (errors) return


        axios.post('/api/v1/acl', newData)
            .then(response => {
                console.log('/api/v1/acl  response: ', response)
                if (response.data.acl) {
                    NotificationManager.success(t('user_add_success') + newData.login + `${t('password:')}` + response.data.password, 'Success!', 10000);
                    setOpen(false)
                }
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
    };

    useEffect(() => {
        setNewData({ ...newData, node: props.parentNode.id })
        // eslint-disable-next-line
    }, [props.parentNode.id])

    return (
        <div style={{ flexGrow: 1, padding: '0 4px' }}>
            <Button
                variant="outlined"
                color="primary"
                aria-label="add"
                onClick={handleClickOpen}
                size="large"
                style={{ width: '100%' }}
                className={classes.mainBtn}
            >
                <Typography variant="h6" component="h6">{t('add')}</Typography>
                <Icon fontSize="large" classes={{ root: classes.mainBtnIcon }}>person_add</Icon>
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" classes={{ paper: classes.paper }}>
                <div className={classes.paper}>
                    <form className={classes.form} noValidate onSubmit={onSubmit}>
                        <DialogTitle id="form-dialog-title">{t('add_user_to')} "{props.parentNode.name}"</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="caption" component="div">
                                <div dangerouslySetInnerHTML={{ __html: t('add_user_dialog_description') }} />
                            </DialogContentText>

                            <FormControl component="fieldset" className={classes.formControl}>
                                <Typography variant="h5" component="legend">{t('user_types.text')}</Typography>
                                <Divider />
                                <RadioGroup row aria-label="position"
                                    className={classes.radioGroup}
                                    name="permission"
                                    defaultValue={newData.permission}
                                    value={newData.permission}
                                    onChange={onChange}
                                >
                                    {
                                        userTypes.map((userType) => {
                                            return <FormControlLabel
                                                value={userType.type}
                                                control={<Radio color="primary" />}
                                                label={
                                                    <>
                                                        {userIcon(userType.type)}
                                                        <small style={{ top: -12, position: 'relative' }}>
                                                            {t(`user_types.${userType.type}`)}
                                                        </small>
                                                    </>
                                                }
                                                labelPlacement="top"
                                                key={userType.type}
                                                className={classes.formControlLabel}
                                            />
                                        })
                                    }
                                </RadioGroup>
                                <Divider />
                            </FormControl>

                            <TextField
                                autoFocus
                                margin="dense"
                                name="login"
                                label={t('login')}
                                type="login"
                                fullWidth
                                value={newData.login}
                                onInput={onChange}
                                error={!newData.login}
                                helperText={!newData.login ? t('field_is_required') : ' '}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary" variant="outlined"
                                className={classes.submit}>
                                {t('cancel')}
                            </Button>
                            <Button type="submit" variant="contained" color="primary"
                                className={classes.submit}>
                                {t('add')}
                            </Button>
                        </DialogActions>
                    </form>
                    {/*<pre>{JSON.stringify(newData, null, 4)}</pre>*/}
                </div>
            </Dialog>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from "react-i18next";
import axios from 'axios'
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import WidgetsIcon from "@material-ui/icons/Widgets";
//import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";
import Icon from "@material-ui/core/Icon";
import MenuItem from '@mui/material/MenuItem';





//import { alpha } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // width: 800,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: 64,
        height: 64
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flex: 1,
        '& span': {
            // color: theme.palette.common.white
        }
    },
    image: {
        width: '100%'
    },
    formControl: {
        flex: 1,
        display: 'flex'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: "space-around",
        marginTop: 16,
        marginBottom: 8
    },
    formControlLabel: {
        borderRadius: 9,
        border: '1px solid #DDD',
        padding: 8,
        width: 110,
        margin: 4
    },
    mainBtn: {
        minHeight: 144,
        flexWrap: 'wrap',
        display: "block"
    },
    mainBtnIcon: {
        display: 'block',
        width: '100%',
        fontSize: '6rem',
        color: 'green'
        ,
        /*   '&:hover':{
              color: alpha(theme.palette.primary.main, 1)
          } */
    }
}));

const nodeTypes = [
    {
        type: "node",
        accepted: ["node"]
    },
    {
        type: "room",
        accepted: ["node"]
    },
    {
        type: "machine",
        accepted: ["room"]
    }
]

/* DO NOT REMOVE NEXT LINES! It's NOT a comment. It's needed to auto generate missing translation fields*/
// t('node_types.node')
// t('node_types.room')
// t('node_types.group')
// t('node_types.point')


const nodeIcon = type => {
    switch (type) {
        case 'node':
            return <DeviceHubIcon />
        case 'room':
            return <MeetingRoomIcon />
        case 'machine':
            return <WidgetsIcon />

        default:
            return null

    }
}
const currencies = [
    {
        value: 'UAH',
        label: 'UAH',
    },
    {
        value: 'USD',
        label: 'USD',
    },
    {
        value: 'EUR',
        label: 'EUR',
    }
];
export default function AddNodeDialog(props) {

    const { t } = useTranslation();
    const history = useHistory()
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };


    const [currency, setCurrency] = React.useState('UAH');

    const handleChange = ({ target: { value } }) => {
        setCurrency(value);
        setNewData({ ...newData, "currency": value })

    };

    const handleClose = () => {
      
        setOpen(false);
    };

    const [newData, setNewData] = useState({
        name: '',
        node: props.parentNode.id,
        description: '',
        node_type: '',
        currency: 'UAH' // nodeTypes.filter(nodeType=>nodeType.accepted.includes(props.parentNode.type))[0].type
    });

    const onChange = ({ target: { name, value } }) => {
        setNewData({ ...newData, [name]: value })
    };


    const onSubmit = (event) => {

        if (event) {
            event.preventDefault();
        }

        console.log('submit')
        // let errors = 0;

        // Object.keys(newData).forEach(e => {
        //     if (!newData[e]) {
        //         NotificationManager.error(`${t(e)}: ${t('field_is_required')}`, 'Oops!', 6666)
        //         errors++
        //     }
        // });

        // if (errors) return

        axios.post('/api/v1/nodes', newData)
            .then(response => {
                console.log('/api/v1/nodes  response: ', response)
                if (response.data.node) {

                    NotificationManager.success(t('node_add_success'), 'Success!', 3000);
                    props.updateTree();
                    handleClose();
                    history.push(`/node/${response.data.node.id}`)
                    history.go(1);
                }
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
                handleClose();
            })
    };

    useEffect(() => {
        setNewData({ ...newData, node: props.parentNode.id })
        // eslint-disable-next-line
    }, [props.parentNode.id])

    return (
        <div style={{ flexGrow: 1, padding: '0 4px' }}>
            <Button
                variant="outlined"
                color="primary"
                aria-label="add"
                onClick={handleClickOpen}
                size="large"
                style={{ width: '100%' }}
                className={classes.mainBtn}
            >
                <Typography variant="h6" component="h6">{t('add')}</Typography>
                <Icon fontSize="large" classes={{ root: classes.mainBtnIcon }}>device_hub</Icon>
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" classes={{ paper: classes.paper }}>
                <div className={classes.paper}>
                    <form className={classes.form} noValidate onSubmit={onSubmit}>
                        <DialogTitle id="form-dialog-title">{t('add_node_to')} "{props.parentNode.name}"</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="caption" component="div">
                                <div dangerouslySetInnerHTML={{ __html: t('add_node_dialog_description') }} />
                            </DialogContentText>

                            <FormControl component="fieldset" className={classes.formControl}>
                                <Typography variant="h5" component="legend">{t('node_types.text')}</Typography>
                                <Divider />
                                <RadioGroup row aria-label="position"
                                    className={classes.radioGroup}
                                    name="node_type"
                                    defaultValue={newData.node_type}
                                    onChange={onChange}
                                    value={newData.node_type}
                                >

                                    {
                                        nodeTypes.filter(nodeType => nodeType.accepted.includes(props.parentNode.type)).map((nodeType) => {
                                            return <FormControlLabel
                                                value={nodeType.type}
                                                control={<Radio color="primary" checked={nodeType.type === newData.node_type} />}
                                                label={
                                                    <>
                                                        {nodeIcon(nodeType.type)}
                                                        <small style={{ top: -12, position: 'relative' }}>
                                                            {t(`node_types.${nodeType.type}`)}
                                                        </small>
                                                    </>
                                                }
                                                labelPlacement="top"
                                                key={nodeType.type}
                                                className={classes.formControlLabel}
                                            />
                                        })
                                    }
                                </RadioGroup>
                                <Divider />
                            </FormControl>

                            <TextField
                                autoFocus
                                margin="dense"
                                name="name"
                                label={t('node_name')}
                                type="text"
                                fullWidth
                                value={newData.name}
                                onInput={onChange}
                                error={!newData.name}
                                helperText={!newData.name ? t('field_is_required') : ' '}
                            />

                            {
                                ['room'].includes(newData.node_type) ?
                                    <div>
                                        <TextField

                                            margin="dense"
                                            name="description"
                                            label={t('description')}
                                            type="text"
                                            fullWidth
                                            value={newData.description}
                                            onInput={onChange}
                                            error={!newData.description}
                                            helperText={!newData.description ? t('field_is_required') : ' '}
                                        />
                                        <TextField
                                            margin="dense"
                                            name="currency"
                                            label={t('currency_')}
                                            select
                                            value={currency}
                                            fullWidth
                                            onChange={handleChange}
                                        >
                                            {currencies.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                    </div> : <span />
                            }



                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary" variant="outlined"
                                className={classes.submit}>
                                {t('cancel')}
                            </Button>
                            <Button type="submit" variant="contained" color="primary"
                                className={classes.submit}>
                                {t('add')}
                            </Button>
                        </DialogActions>
                    </form>
                    {/*<pre>{JSON.stringify(newData, null, 4)}</pre>*/}
                </div>
            </Dialog >
        </div >
    );
}

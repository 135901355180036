import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from "react-i18next";
import axios from 'axios'
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import makeStyles from "@material-ui/core/styles/makeStyles";

import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";
import Icon from "@material-ui/core/Icon";
import { alpha } from "@material-ui/core";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // width: 800,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: 64,
        height: 64
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        flex: 1,
        '& span': {
            // color: theme.palette.common.white
        }
    },
    image: {
        width: '100%'
    },
    formControl: {
        flex: 1,
        display: 'flex'
    },
    radioGroup: {
        display: 'flex',
        justifyContent: "space-around",
        marginTop: 16,
        marginBottom: 8
    },
    formControlLabel: {
        borderRadius: 9,
        border: '1px solid #DDD',
        padding: 8,
        width: 110,
        margin: 4
    },
    mainBtn: {
        minHeight: 144,
        flexWrap: 'wrap',
        display: "block"
    },
    mainBtnIcon: {
        display: 'block',
        width: '100%',
        fontSize: '6rem',
        color: alpha(theme.palette.primary.main, 0.8),
        '&:hover': {
            color: alpha(theme.palette.primary.main, 1)
        }
    }
}));



/* DO NOT REMOVE NEXT LINES! It's NOT a comment. It's needed to auto generate missing translation fields*/
// t('node_types.node')
// t('node_types.room')
// t('node_types.group')
// t('node_types.point')




export default function AddMachineDialog(props) {

    const { t } = useTranslation();
    const history = useHistory()
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [newData, setNewData] = useState({
        name: '',
        registration_code: '',
        node: props.parentNode.id,
        node_type: "machine",
        description: '',
        serial_number: '',
        hand_pay_out: true,
        hand_pay_in: true,
        use_bill_validator: true,
        percent_id: 1,
        jackpot_type_id: 1,
        bet_sequence_id: 1,
        denomination_id: 3,



    });

    const [machineConfigs, setMachineConfigs] = useState({
        betSequences: [],
        denominations: [],
        jackpots: [],
        percents: [],


    });


    const onChange = ({ target: { name, value } }) => {
        setNewData({ ...newData, [name]: value })
    };

    const handleChange = ({ target: { name, checked } }) => {
        setNewData({ ...newData, [name]: checked })
    };
    const onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }

        console.log('submit')
        let errors = 0;

        Object.keys(newData).forEach(e => {
            if (!newData[e]) {
                NotificationManager.error(`${t(e)}: ${t('field_is_required')}`, 'Oops!', 6666)
                errors++
            }
        });

        if (errors) return
        console.log("newData: ", newData)
        axios.post(`/api/v1/nodes`, newData)
            .then(response => {
               console.log('/api/v1/nodes  response: ', response)
               if (response.data.node) {

                   NotificationManager.success(t('node_edit_success'), 'Success!', 3000);
                   props.updateTree()
                    history.push(`/node/${response.data.node.id}`)
                    history.go(1);
                   // history.go(-1);
                   handleClose();
                }
            })
            .catch(error => {
               console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
                handleClose();
           })
    };

    const getMachineConfigs = () => {

        axios
            .get(`/api/v1/machine_configs`)
            .then(response => {
                console.log(response)
                setMachineConfigs(response.data.configs)
                // setTransactions(response.data.transactions)
            })
            .catch(error => {
                console.log(error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
    };

    useEffect(() => {

        getMachineConfigs();

        setNewData({ ...newData, node: props.parentNode.id })
        // eslint-disable-next-line
    }, [props.parentNode.id])

    return (
        <div style={{ flexGrow: 1, padding: '0 4px' }}>
            <Button
                variant="outlined"
                color="primary"
                aria-label="add"
                onClick={handleClickOpen}
                size="large"
                style={{ width: '100%' }}
                className={classes.mainBtn}
            >
                <Typography variant="h6" component="h6">{t('add')}</Typography>
                <Icon fontSize="large" classes={{ root: classes.mainBtnIcon }}>device_hub</Icon>
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" classes={{ paper: classes.paper }}>
                <div className={classes.paper}>
                    <form className={classes.form} noValidate onSubmit={onSubmit}>
                        <DialogTitle id="form-dialog-title">{t('add_machine')} "{props.parentNode.name}"</DialogTitle>
                        <DialogContent>
                       


                            <TextField
                                autoFocus
                                margin="dense"
                                name="name"
                                label={t('node_name')}
                                type="text"
                                fullWidth
                                value={newData.name}
                                onInput={onChange}
                                error={!newData.name}
                                helperText={!newData.name ? t('field_is_required') : ' '}
                            />
                             <TextField
                                autoFocus
                                margin="dense"
                                name="registration_code"
                                label={t('registration_code')}
                                type="text"
                                fullWidth
                                value={newData.registration_code}
                                onInput={onChange}
                                error={!newData.registration_code}
                                helperText={!newData.registration_code ? t('field_is_required') : ' '}
                            />

                            <TextField
                                autoFocus
                                margin="dense"
                                name="description"
                                label={t('description')}
                                type="text"
                                fullWidth
                                value={newData.description}
                                onInput={onChange}

                            />


                            <TextField
                                autoFocus
                                margin="dense"
                                name="serial_number"
                                label={t('serial_number')}
                                type="text"
                                fullWidth
                                value={newData.serial_number}
                                onInput={onChange}
                                error={!newData.serial_number}
                                helperText={!newData.serial_number ? t('field_is_required') : ' '}
                            />
                             <FormGroup>
                                <FormControlLabel control={<Checkbox />} label={t('hand_pay_in')} name='hand_pay_in' checked={newData.hand_pay_in}
                                    onChange={handleChange} />
                                <FormControlLabel control={<Checkbox defaultChecked />} label={t('hand_pay_out')} name='hand_pay_out' checked={newData.hand_pay_out}
                                    onChange={handleChange} />
                                <FormControlLabel control={<Checkbox />} label={t('use_bill_validator')} name='use_bill_validator' checked={newData.use_bill_validator}
                                    onChange={handleChange} />
                            </FormGroup>

                            <TextField
                                margin="dense"
                                name="percent_id"
                                label={t('percent_id')}
                                select
                                value={newData.percent_id}
                                fullWidth
                                onChange={onChange}
                            // onChange={handleChange}
                            >
                                {machineConfigs.percents.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.percent}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                margin="dense"
                                name="jackpot_type_id"
                                label={t('jackpot')}
                                select
                                value={newData.jackpot_type_id}
                                fullWidth
                                onChange={onChange}
                            // onChange={handleChange}
                            >
                                {machineConfigs.jackpots.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.jackpot}
                                    </MenuItem>
                                ))}
                            </TextField>


                            <TextField
                                margin="dense"
                                name="bet_sequence_id"
                                label={t('bet_sequence')}
                                select
                                value={newData.bet_sequence_id}
                                fullWidth
                                onChange={onChange}
                            // onChange={handleChange}
                            >
                                {machineConfigs.betSequences.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.bet_sequence}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                margin="dense"
                                name="denomination_id"
                                label={t('denomination')}
                                select
                                value={newData.denomination_id}
                                fullWidth
                                onChange={onChange}
                            // onChange={handleChange}
                            >
                                {machineConfigs.denominations.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.denomination}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary" variant="outlined"
                                className={classes.submit}>
                                {t('cancel')}
                            </Button>
                            <Button type="submit" variant="contained" color="primary"
                                className={classes.submit}>
                                {t('add')}
                            </Button>
                        </DialogActions>
                    </form>
                    {/*<pre>{JSON.stringify(newData, null, 4)}</pre>*/}
                </div>
            </Dialog>
        </div>
    );
}

import { combineReducers } from 'redux';
import { checkedItems, expandedItems, fetchACLs, fetchSideMenuItems, getInfo, menuOpen, previewOpen } from "./store";
// eslint-disable-next-line
import { fetchWidgetsAData, fetchWidgetsBData, fetchWidgetsCData } from "./widgets";
import { fetchCustomerInfo } from "./customer";

export const reducers = combineReducers({
    sideMenuOpened: menuOpen,
    previewSheetOpened: previewOpen,
    sideMenuExpanded: expandedItems,
    sideMenuChecked: checkedItems,
    sideMenuItems: fetchSideMenuItems,
    nodeInfo: getInfo,
    // widgetsAData: fetchWidgetsAData,
    // widgetsBData: fetchWidgetsBData,
    // widgetsCData: fetchWidgetsCData,
    customerInfo: fetchCustomerInfo,
    aclsInfo: fetchACLs
});

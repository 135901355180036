import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { alpha, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Container, Grid, withStyles } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { useDispatch, useSelector } from "react-redux";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import WidgetsIcon from "@material-ui/icons/Widgets";
import Badge from '@material-ui/core/Badge';
import AddNodeDialog from "./addNodeDialog";
import AddMachineDialog from "./addMachineDialog";
import EditNodeDialog from "./editNodeDialog";
import EditMachineDialog from "./editMachineDialog";
import DeleteNodeDialog from "./deleteNodeDialog";
import TextField from '@mui/material/TextField';
import AddUserDialog from "./addUserDialog";
import EditUserDialog from "./editUserDialog";
import DeleteUserDialog from "./deleteUserDialog";
import AddCardDialog from "./addCardDialog";
//import EditCardDialog from "./editCardDialog";
//import DeleteCardDialog from "./deleteCardDialog";
//import Divider from "@material-ui/core/Divider";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import BuildIcon from "@material-ui/icons/Build";
import FaceIcon from "@material-ui/icons/Face";
import Box from '@mui/material/Box';
//import CardDepositDialog from "./cardDepositDialog";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import MaterialTable from '@material-table/core';
import tableLocalizations from '../common/tableLocalizations.json';
import axios from 'axios';
import { NotificationManager } from "react-notifications";
import GetRoomTransactionsDialog from "./getRoomTransactionsDialog";
import Preloader from "../common/Preloader";
import clsx from "clsx";
//import PointCard from "./PointCard";
import GetCardsListDialog from "./getCardsListDialog";
import GetRoomCustomersListDialog from "./getRoomCustomersListDialog";
import GetReportDialog from "./getReportDialog";
//import { textAlign } from '@material-ui/system';

import { setMenuItems } from "../../redux/store";
import { locales } from 'moment';



const _ = require('lodash')

const useStyles = makeStyles((theme) => (
    {
        root: {
            flexGrow: 1,
        },
        cardHeader: {
            backgroundColor: alpha(theme.palette.primary.main, 0.75),
            color: theme.palette.common.white

        },
        actionsNav: {
            justifyContent: "space-around",
            flexWrap: "wrap",
            display: "flex"
        },
        cardHeaderAction: {
            textAlign: "center",
            alignSelf: "center"
        }
    }
));

const nodeIcon = type => {
    switch (type) {
        case 'node':
            return <DeviceHubIcon fontSize="large" />
        case 'room':
            return <MeetingRoomIcon fontSize="large" />
        case 'machine':
            return <WidgetsIcon fontSize="large" />

        default:
            return null

    }
}

const userIcon = type => {
    switch (type) {
        case 'owner':
            return <AssignmentIndIcon />
        case 'manager':
            return <HowToRegIcon />
        case 'tech':
            return <BuildIcon />
        case 'operator':
            return <FaceIcon />
        default:
            return null

    }
}


const StyledBadge = withStyles((theme) => ({
    root: {
        marginTop: -16
    },
    badge: {
        right: -24,
        top: 0,
    },
}))(Badge);

export default function NodeIndex(props) {
    const classes = useStyles();

    const { t, i18n } = useTranslation()




    const fixedHeightSkeleton = clsx(classes.paper, classes.fixedHeightSkeleton);

    const dispatch = useDispatch()

    const nodeTreeItems = useSelector(state => state.sideMenuItems)
    const sideMenuExpanded = useSelector(state => state.sideMenuExpanded)
    const date = new Date();




    const flattenItems = (items, key) => {
        return items.reduce((flattenedItems, item) => {
            flattenedItems.push(item)
            if (Array.isArray(item[key])) {
                flattenedItems = flattenedItems.concat(flattenItems(item[key], key))
                flattenedItems.map(i => {
                    i.expanded = props.match.params.id.startsWith(i.id) ? true : false
                    return i
                })
            }
            return flattenedItems
            // eslint-disable-next-line
        }, [])
    }

    const updateTree = () => {
        axios.get('/api/v1/tree')
            .then(acl_response => {
                dispatch(setMenuItems(acl_response.data))
            })
            .catch(error => {
                console.log(error, error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
    }

    const [nodeInfo, setNodeInfo] = useState({})
    const getInfo = () => {
        console.log("getInfo", props.match.params.id)
        //dispatch(setNodeInfo(''))
        setNodeInfo({})
        axios.get('/api/v1/nodes/' + props.match.params.id + '/info')
            .then(acl_response => {
                console.log(acl_response.data)
                if (acl_response.data.node_info.machines) {
                    for (let i = 0; i < acl_response.data.node_info.machines.length; i++) {
                        //acl_response.data.node_info.machines[i].total_out = (acl_response.data.node_info.machines[i].total_out / 100).toFixed(2) + " " + acl_response.data.node_info.currency
                        //     acl_response.data.node_info.machines[i].total_in = (acl_response.data.node_info.machines[i].total_in / 100).toFixed(2) + " " + acl_response.data.node_info.currency
                        //acl_response.data.node_info.machines[i].saldo = (acl_response.data.node_info.machines[i].saldo / 100).toFixed(2) + " " + acl_response.data.node_info.currency
                        acl_response.data.node_info.machines[i].total_in = (acl_response.data.node_info.machines[i].total_in * 0.01)
                        acl_response.data.node_info.machines[i].saldo = (acl_response.data.node_info.machines[i].saldo * 0.01)
                        acl_response.data.node_info.machines[i].total_out = (acl_response.data.node_info.machines[i].total_out * 0.01)
                    }
                    acl_response.data.node_info.saldo = (acl_response.data.node_info.saldo * 0.01).toFixed(2) + " " + acl_response.data.node_info.currency
                    acl_response.data.node_info.total_out = (acl_response.data.node_info.total_out * 0.01).toFixed(2) + " " + acl_response.data.node_info.currency
                    acl_response.data.node_info.total_in = (acl_response.data.node_info.total_in * 0.01).toFixed(2) + " " + acl_response.data.node_info.currency
                }
                if (acl_response.data.node_info.last_sync) {

                    acl_response.data.node_info.saldo = (acl_response.data.node_info.saldo * 0.01).toFixed(2) + " " + acl_response.data.node_info.currency
                    acl_response.data.node_info.total_out = (acl_response.data.node_info.total_out * 0.01).toFixed(2) + " " + acl_response.data.node_info.currency
                    acl_response.data.node_info.total_in = (acl_response.data.node_info.total_in * 0.01).toFixed(2) + " " + acl_response.data.node_info.currency
                }
                //console.log('date',acl_response.data.node_info.last_sync,"  parse: ",Intl.DateTimeFormat('uk',{ dateStyle: 'medium', timeStyle: 'medium' }).format(Date.parse(acl_response.data.node_info.last_sync)))
                setNodeInfo(acl_response.data.node_info)

            })
            .catch(error => {
                console.log(error, error.response)
                NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
            })
    };




    const item = _.find(flattenItems(nodeTreeItems, 'children'), ['id', props.match.params.id])

    useEffect(() => {

        if (!sideMenuExpanded.length) {
            let expanded = {}

            flattenItems(nodeTreeItems, 'children').forEach(i => {
                expanded[i.id] = i.expanded
            })
            dispatch({
                type: 'SET_EXPANDED',
                payload: expanded
            })
        }
        if (props.match.params.id != nodeInfo.node_id) {
            getInfo()
        }


    }, [item, sideMenuExpanded.length, props.match.params.id])


    return (
        <DocumentTitle title={'Andromeda || ' + t('nodePage.docTitle')}>
            <Container maxWidth={false} className={classes.root} disableGutters={true}>
                <CssBaseline />

                {
                    item
                        ? <Card className={classes.root}>
                            <CardHeader
                                title={
                                    <>
                                        {nodeIcon(item.type)}
                                        <StyledBadge badgeContent={t(`node_types.${item.type}`)} color="primary">
                                            {item.name}
                                        </StyledBadge>

                                    </>
                                }
                                subheader={item.id}
                                className={classes.cardHeader}
                                classes={{
                                    action: classes.cardHeaderAction
                                }}
                                action={
                                    <>
                                        {userIcon(item.permissions[0])}<br />
                                        {t(`user_types.${item.permissions[0]}`)}
                                    </>
                                }
                            />

                            <CardContent className={classes.actionsNav}>

                                {!!item.permissions.length &&
                                    <>

                                        <Grid container spacing={2} style={{ margin: 5 }}>

                                            {['room', 'machine'].includes(item.type) && nodeInfo.name &&
                                                <Box sx={{ m: 1, width: '35ch' }}>

                                                    <TextField
                                                        label={t('name')}
                                                        defaultValue=' '
                                                        value={nodeInfo.name}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }} />
                                                </Box>

                                            }

                                            {['room', 'machine'].includes(item.type) && nodeInfo.description &&
                                                <Box sx={{ m: 1, width: '35ch' }}>

                                                    <TextField
                                                        label={t('description')}
                                                        defaultValue=' '
                                                        value={nodeInfo.description}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }} />
                                                </Box>

                                            }




                                            {['room', 'machine'].includes(item.type) && nodeInfo.currency &&
                                                <Box sx={{ m: 1, width: '35ch' }}>

                                                    <TextField label={t('total_in')}
                                                        defaultValue=' '
                                                        value={nodeInfo.total_in}
                                                        InputProps={{
                                                            readOnly: true,
                                                            // endAdornment: (
                                                            //     <InputAdornment position="end">
                                                            //         <MonetizationOnIcon sx={{ color: 'action.active', mr: 1, my: 2.2 }} />
                                                            //     </InputAdornment>
                                                            // ),
                                                        }} />

                                                </Box>

                                            }


                                            {['room', 'machine'].includes(item.type) && nodeInfo.currency &&
                                                <Box sx={{ m: 1, width: '35ch' }}>

                                                    <TextField label={t('total_out')}
                                                        defaultValue=' '
                                                        value={nodeInfo.total_out}
                                                        InputProps={{
                                                            readOnly: true,
                                                            // endAdornment: (
                                                            //     <InputAdornment position="end">
                                                            //         <MonetizationOnIcon sx={{ color: 'action.active', mr: 1, my: 2.2 }} />
                                                            //     </InputAdornment>
                                                            // ),
                                                        }} />

                                                </Box>

                                            }


                                            {['room', 'machine'].includes(item.type) && nodeInfo.currency &&
                                                <Box sx={{ m: 1, width: '35ch' }}>

                                                    <TextField label={t('saldo')}
                                                        defaultValue=' '
                                                        value={nodeInfo.saldo}
                                                        InputProps={{
                                                            readOnly: true,
                                                            // endAdornment: (
                                                            //     <InputAdornment position="end">
                                                            //         <MonetizationOnIcon sx={{ color: 'action.active', mr: 1, my: 2.2 }} />
                                                            //     </InputAdornment>
                                                            // ),
                                                        }} />

                                                </Box>

                                            }
                                            {['machine'].includes(item.type) && nodeInfo.serial_number &&
                                                <Box sx={{ m: 1, width: '35ch' }}>

                                                    <TextField label={t('serial_number')}
                                                        defaultValue=' '
                                                        value={nodeInfo.serial_number}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }} />

                                                </Box>

                                            }
                                            {['machine'].includes(item.type) && nodeInfo.last_sync &&
                                                <Box sx={{ m: 1, width: '35ch' }}>

                                                    <TextField label={t('last_sync')}
                                                        defaultValue=' '
                                                        value={Intl.DateTimeFormat('uk', { dateStyle: 'short', timeStyle: 'medium' }).format(Date.parse(nodeInfo.last_sync))}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }} />

                                                </Box>

                                            }
                                            {['machine'].includes(item.type) && nodeInfo.percent &&
                                                <Box sx={{ m: 1, width: '35ch' }}>

                                                    <TextField label={t('percent')}
                                                        defaultValue=' '
                                                        value={nodeInfo.percent}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }} />

                                                </Box>

                                            }
                                            {['machine'].includes(item.type) && nodeInfo.bet_sequence &&
                                                <Box sx={{ m: 1, width: '35ch' }}>

                                                    <TextField label={t('bet_sequence')}
                                                        defaultValue=' '
                                                        value={nodeInfo.bet_sequence}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }} />

                                                </Box>

                                            }
                                            {['machine'].includes(item.type) && nodeInfo.denomination &&
                                                <Box sx={{ m: 1, width: '35ch' }}>

                                                    <TextField label={t('denomination')}
                                                        defaultValue=' '
                                                        value={nodeInfo.denomination}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }} />

                                                </Box>

                                            }
                                            {['machine'].includes(item.type) && nodeInfo.jackpot_type &&
                                                <Box sx={{ m: 1, width: '35ch' }}>

                                                    <TextField label={t('jackpot')}
                                                        defaultValue=' '
                                                        value={nodeInfo.jackpot_type}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }} />

                                                </Box>

                                            }







                                        </Grid>

                                        <Grid container spacing={0}>
                                            {['room'].includes(item.type) && nodeInfo.machines &&
                                                <Grid item xs={12} md={12} lg={12}>
                                                    {!nodeInfo.machines
                                                        ?
                                                        <Paper className={fixedHeightSkeleton}>
                                                            <Skeleton animation="wave" width="100%" />
                                                            <Skeleton variant="circle" width={40} height={40} />
                                                            <Skeleton animation="wave" width="100%" />
                                                        </Paper>
                                                        :
                                                        <MaterialTable
                                                            style={{ boxShadow: "none" }}
                                                            title={t('machine_list')}
                                                            columns={[


                                                                { field: 'id', title: t('id'), width: 60 },
                                                                { field: 'name', title: t('name') },
                                                                { field: 'last_sync', title: t('last_sync'), dateSetting: { locale: 'uk' }, type: 'datetime' },
                                                                { field: 'registered', title: t('registered'), defaultSort: 'desc', hidden: true },
                                                                { field: 'serial_number', title: t('serial_number'), hidden: true },
                                                                {
                                                                    field: 'total_in', title: t('total_in'), currencySetting: {
                                                                        locale: 'uk',
                                                                        currencyCode: nodeInfo.currency,
                                                                    }, type: 'currency', cellStyle: { textAlign: 'left' }
                                                                },
                                                                {
                                                                    field: 'total_out', title: t('total_out'), currencySetting: {
                                                                        locale: 'uk',
                                                                        currencyCode: nodeInfo.currency,
                                                                    }, type: 'currency', cellStyle: { textAlign: 'left' }
                                                                },
                                                                {
                                                                    field: 'saldo', title: t('saldo'), currencySetting: {
                                                                        locale: 'uk',
                                                                        currencyCode: nodeInfo.currency,
                                                                    }, type: 'currency', cellStyle: { textAlign: 'left' }, headerStyle: { align: 'right' },
                                                                }

                                                                /*   {title: t('id'), field: "ID", cellStyle: {textAlign: 'center'}},
                                                    {title: t('name'), field: "name", cellStyle: {textAlign: 'center'}},
                                                    {title: t('address'), field: "address", cellStyle: {textAlign: 'center'}},
                                                    {title: t('description'), field: "description", cellStyle: {textAlign: 'center'} }, */

                                                            ]}


                                                            localization={tableLocalizations[i18n.language]}
                                                            data={nodeInfo.machines}

                                                            options={{
                                                                // actionsColumnIndex: -1,



                                                                showTitle: true,
                                                                // pageSize: All,
                                                                pageSizeOptions: [5, 20, 100],


                                                                rowsPerPage: 30,
                                                                rowStyle: x => {



                                                                    if (!x.registered) {
                                                                        return {
                                                                            color: 'red'
                                                                        }
                                                                    }
                                                                },

                                                                search: false,


                                                            }}




                                                        />

                                                    }

                                                </Grid>}
                                        </Grid>


                                        <Grid container spacing={1} style={{ margin: '24px 0 4px 0' }}>
                                            {['node', 'room'].includes(item.type) &&
                                                <Typography variant="h5" color="primary">{t('nodes')}</Typography>}
                                        </Grid>
                                        <Grid container spacing={1} style={{ margin: 2 }}>
                                            {['node'].includes(item.type) && <AddNodeDialog parentNode={item} updateTree={updateTree} />}
                                            {['room'].includes(item.type) && <AddMachineDialog parentNode={item} updateTree={updateTree} />}
                                            {['node', 'room'].includes(item.type) && <EditNodeDialog parentNode={item} closeDialog={getInfo} updateTree={updateTree} />}


                                            {['machine'].includes(item.type) && <EditMachineDialog parentNode={item} machineInfo={nodeInfo} closeDialog={getInfo} updateTree={updateTree} />}
                                            {['node', 'room', 'machine'].includes(item.type) && <DeleteNodeDialog parentNode={item} updateTree={updateTree} />}

                                        </Grid>
                                        <Grid container spacing={1} style={{ margin: '24px 0 4px 0' }}>
                                            {['node', 'room'].includes(item.type) &&
                                                <Typography variant="h5" color="primary">{t('users')}</Typography>}
                                        </Grid>

                                        <Grid container spacing={1} style={{ margin: 2 }}>
                                            {['node', 'room'].includes(item.type) && <AddUserDialog parentNode={item} />}
                                            {['node', 'room'].includes(item.type) && ['owner', 'manager'].includes(item.permissions[0]) &&
                                                <GetRoomCustomersListDialog parentNode={item} />}
                                        </Grid>

                                        <Grid container spacing={1} style={{ margin: '24px 0 4px 0' }}>
                                            {['room'].includes(item.type) &&
                                                <Typography variant="h5" color="primary">{t('card')}</Typography>}
                                        </Grid>
                                        <Grid container spacing={1} style={{ margin: 2 }}>
                                            {['room'].includes(item.type) && <AddCardDialog parentNode={item} />}
                                            {['room'].includes(item.type) && <GetCardsListDialog parentNode={item} />}

                                        </Grid>
                                        <Grid container spacing={1} style={{ margin: 2 }}>
                                            {['machine'].includes(item.type) && <GetRoomTransactionsDialog parentNode={item} currency={nodeInfo.currency} />}
                                            {['node', 'room', 'machine'].includes(item.type) && <GetReportDialog parentNode={item} />}

                                        </Grid>


                                    </>
                                }
                            </CardContent>
                            {/*   <Divider />
                            <CardContent>
                                <div dangerouslySetInnerHTML={{ __html: t('nodePage.intro') }} />
                            </CardContent> */}
                            <CardActions>

                            </CardActions>
                        </Card>
                        : <Preloader />
                }

            </Container>
        </DocumentTitle >
    );
};
